<template>
  <div class="user-vip personal-user-vip" :class="[isVip && 'vip']">
    <div class="personal-user-vip-top">
      <div class="user-vip-info">
        <div>
          <img
            class="avatar"
            :src="
              userInfo.avatar ||
              'https://qncweb.ktvsky.com/20211108/vadd/978f457f5f485e19364f234b2cfcbf07.png'
            "
          />
        </div>
        <div class="user-vip-info-user">
          <div class="username">
            {{ userInfo.username }}
          </div>
          <div v-if="isLogin" class="desc">{{ vipDesc }}</div>
        </div>

        <div class="user-vip-info-entry">
          <template v-if="from === 'profile'">
            <div class="user-vip-info-entry-active" @click="handleExchange">
              兑换VIP
            </div>
          </template>
          <template v-else>
            <div class="user-vip-info-entry-active" @click="handleProfile">
              会员中心
            </div>
            <!-- <div @click="handleSetting">设置</div> -->
          </template>
        </div>
      </div>

      <div class="personal-user-vip-top-packages-title">
        <img
          src="https://qncweb.ktvsky.com/20240913/vadd/cf64ffa9a97f89438c561d13bef75ce5.png"
        />
      </div>
    </div>

    <div class="personal-user-vip-packages">
      <div class="vip-packages">
        <!-- <PerfectScrollbar> -->
        <div class="ps">
          <div
            class="vip-packages-item"
            v-for="item in packages"
            :key="item.id"
            @click="handleClickItem(item)"
          >
            <div
              class="vip-packages-item-content"
              :class="[item.id === selectItem.id && 'active']"
            >
              <div class="days">{{ item.title }}</div>
              <div class="day-price">
                <span>¥</span>{{ formatValue(item.day_fee) }}<span>元/天</span>
              </div>
              <div class="price">
                <span>¥{{ formatValue(item.fee) }}</span>
                <span v-if="item.fee !== item.old_fee" class="origin"
                  >¥{{ formatValue(item.old_fee) }}</span
                >
              </div>
            </div>

            <div
              v-if="item.tips"
              class="tips"
              :class="item.isOrange && 'orange'"
            >
              {{ item.tips }}
            </div>
          </div>
        </div>
        <!-- </PerfectScrollbar> -->
      </div>
    </div>

    <div class="personal-user-vip-openvip">
      <div class="pay-info">
        <div class="left">
          <h3>微信扫码支付</h3>
          <div class="price" v-html="formatPayTitle"></div>
          <p>有效期至-{{ expirationDate }}</p>
        </div>
        <div class="code">
          <img :src="qrCodeURL" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getVipPkg, getVipPkgQr } from '@/service/vip'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

export default {
  name: 'vipComponent',
  components: {
    // PerfectScrollbar
  },
  props: {
    from: {
      type: String,
      default: 'mine',
    },
    fr: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const { from, fr } = toRefs(props)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { getQRCodeURL } = useQRCode()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const setting = computed(() => store.state.setting)
    const loginSendVip = computed(() => store.state.activityInfo.loginSendVip)
    const isShowSignIn = computed(() => store.state.signin.isShowSignIn)
    const unionid = computed(() => store.state.userInfo.unionid)

    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const packages = ref([])
    const selectItem = ref({})

    const vipDesc = computed(() => {
      return isVip.value
        ? `会员有效期至: ${vipInfo.value.end_time
            .split(' ')[0]
            .replaceAll('-', '.')}`
        : '未开通 VIP'
    })

    const expirationDate = computed(() => {
      if (!selectItem.value.days) return ''
      const currentDate = isVip.value
        ? new Date(vipInfo.value.end_time)
        : new Date()
      const expirationDate = addDays(currentDate, selectItem.value.days)

      return format(expirationDate, 'yyyy.MM.dd')
    })

    const formatValue = (value) => {
      if (value === undefined || isNaN(value)) {
        return 'N/A'
      }
      return value / 100
    }

    const frObj = {
      mine: 1830,
      profile: 1831,
    }

    const getPayLog = () => {
      if (fr.value) return fr.value
      if (from.value === 'mv') return 1834
      if (frObj[route.name]) {
        return frObj[route.name]
      }
      return 1759
    }

    const getVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: selectItem.value.id,
          fr: getPayLog(),
        })

        const qr = get(data, 'qr', '')
        if (qr) {
          const qrCodeData = await getQRCodeURL(qr)
          if (qrCodeData) {
            qrCodeURL.value = qrCodeData
            // store.dispatch('startVipInfoStatus');
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const formatPayTitle = computed(() => {
      const regex = /(\d+)(年|天)/g
      if (!selectItem.value.title) return ''

      const matches = selectItem.value.title.match(regex)
      if (!matches) return ''

      const formatted = matches
        .map((match) => {
          const [_, number, unit] = match.match(/(\d+)(年|天)/)
          return `<span class="user-vip-year-num">${number}</span>${unit}`
        })
        .join(' ')

      return formatted
    })

    const fetchConfig = async () => {
      if (!unionid.value) return
      const res = await getVipPkg(unionid.value)
      packages.value = res.data
      if (packages.value.length === 3) {
        const index = packages.value.findIndex((item) => !!item.tips)
        packages.value[index].isOrange = true
      } else {
        const reversedIndex = packages.value
          .slice()
          .reverse()
          .findIndex((item) => !!item.tips)
        const index = packages.value.length - 1 - reversedIndex
        packages.value[index].isOrange = true
      }

      const active =
        packages.value.find((item) => item.id === res.recommend_pkg) ||
        packages.value[0]
      selectItem.value = active

      getVipQrcode()
    }

    const handleClickItem = (item) => {
      qrCodeURL.value =
        'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
      selectItem.value = item
      getVipQrcode()
    }

    const handleProfile = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
        },
      })
      router.push({
        name: 'profile',
      })
    }

    const handleExchange = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
          str5: '会员兑换',
          str6: 'click',
        },
      })
      router.push({
        name: 'vipExchange',
      })
    }

    const handleSetting = () => {
      router.push({
        name: 'setting',
      })
    }

    watch(unionid, (val) => {
      if (val) {
        fetchConfig()
      }
    })

    onBeforeMount(() => {
      fetchConfig()
    })

    return {
      vipInfo,
      userInfo,
      isLogin,
      isVip,
      setting,
      isShowSignIn,
      loginSendVip,
      vipDesc,
      formatValue,
      packages,
      expirationDate,
      selectItem,
      handleClickItem,
      qrCodeURL,
      handleProfile,
      handleExchange,
      handleSetting,
      formatPayTitle,
    }
  },
}
</script>

<style lang="stylus" scoped>
.personal-user-vip
  width 872px
  height 916px
  position fixed
  right 80px
  // top 164px
  top 145px
  display flex
  flex-direction column
  background none

  // justify-content space-between
  &-top
    display flex
    flex-direction column
    width 100%
    height 280px
    margin-bottom 0px
    background transparent
    border-radius 10px
    background rgba(255, 255, 255, 0.05)
    &-packages-title
      width 100%
      height 96px !important
      display flex
      justify-content center
      align-items center
      border-top 2px solid rgba(0, 0, 0, 0.3)
      img
        width 763px
        height 32px
  &-packages
    .vip-packages
      min-width 640px
      height fit-content
      .ps
        display flex
        padding-top 30px
        overflow-x auto
      &-item
        width: 200px;
        height: 280px;
        position relative
        text-align center
        margin-right 20px
        flex-shrink 0
        overflow auto
        &-content
          width: 200px;
          height: 260px;
          display flex
          flex-direction column
          justify-content center
          align-items center
          position absolute
          bottom 0
          left 0
          z-index 1
          border-radius: 10px;
          background: linear-gradient(217deg, rgba(255, 237, 219, 0.12) 0%, rgba(255, 255, 255, 0.02) 100%), rgba(255, 255, 255, 0.04);
          backdrop-filter: blur(50px);
          &.active
            background: linear-gradient(322deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%);
            .days
              background: linear-gradient(270deg, #625F66 0%, #222124 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            .day-price
              color: #933000;
            .price
              color rgba(172, 98, 0, 1)
              .origin
                color rgba(172, 98, 0, 0.5)
        .tips
          padding 0 10px
          height 40px
          line-height 40px
          font-size 22px
          color #fff
          position absolute
          left 0
          top 0px
          background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
          border-radius 10px 10px 10px 0
          z-index 1
          &.orange
            background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)
        .days
          color: #FFD7AE;
          font-size: 28px;
          font-weight: 300;
          margin-top 40px
        .day-price
          color: #FFD7AE
          font-size: 52px;
          margin 12px 0 9px
          span
            font-size: 24px;
        .price
          font-size: 24px;
          color rgba(255, 255, 255, 1)
          display flex
          justify-content center
          font-weight: 300;
          .origin
            color: rgba(255, 255, 255, 0.5)
            font-size: 24px;
            text-decoration line-through
            margin-left 8px
  .user-vip
    &-top
      width 100%
      height 120px
      display flex
      justify-content space-between
      align-items center
      margin-bottom 60px
      @media screen and (max-width 1200px)
        margin-bottom 40px
    &-info
      display flex
      align-items center
      position relative
      margin 32px 0
      padding 0 36px

      .avatar
        width 120px
        height 120px
        border-radius 100%
        margin-right 24px
      &-user
        .username
          max-width 439px
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          color rgba(255, 255, 255, 1)
          font-size 32px
          line-height 1
          margin-bottom 16px
        .desc
          color rgba(255, 255, 255, 0.4)
          font-size 24px
          line-height 1
          padding-left 66px
          background-repeat no-repeat
          background-size 50px auto
          background-image url(https://qncweb.ktvsky.com/20231206/vadd/342350516d9b0eef7c2a5989dfcec1c5.png)
          background-position left center
      &-entry
        display flex
        position absolute
        right 0
        top 20px
        padding-right 36px
        div
          display flex
          justify-content center
          align-items center
          width: 200px;
          height: 80px;
          border-radius: 100px;
          border: 2px solid rgba(255, 255, 255, 0.10);
          background: rgba(255, 255, 255, 0.08);
          color: rgba(255, 255, 255, 0.60);
          font-size 28px
        &-active
          color rgba(219, 174, 106, 1) !important
          // margin-right 32px
    &.vip
      .username
        color: #E3AB5D;
      .desc
        background-image url(https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png)
  &-openvip
    width 872px
    height 248px
    background rgba(255, 255, 255, 0.06)
    backdrop-filter blur(50px)
    display flex
    border-radius 10px
    overflow hidden
    margin-top 30px
    .pay-info
      width 872px
      height 248px
      padding 24px 24px 24px 36px
      display flex
      justify-content space-between
      align-items center
      .left
        width 220px
        text-align left
        padding 0
        ::v-deep .price
          color rgba(219, 174, 106, 1)
          font-size 28px
          margin-bottom 4px
          height 82px
          span
            font-size 70px
            line-height 70px
            // margin-left 16px
        h3
          color rgba(255, 255, 255, 1)
          font-weight 700
          font-size 32px
          margin-bottom 44px
        p
          color rgba(255, 255, 255, 0.6)
          font-size 22px
      .code
        width 200px
        height 200px
        background #fff
        border-radius 8px
        padding 0px
        margin 0
        overflow hidden
        img
          width 188px
          height 188px
          margin 6px
</style>
